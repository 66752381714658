.block.core-values{
	position: relative;
	padding: 50px 20px;



	h1,h2,h3,h4,h5,h6{
		padding: 0 0 20px 0;
		font-weight: bold;
		text-align: left;
	}
	header {
		h1,h2,h3,h4,h5,h6{
			text-align: center;
		}
	}

	h3,h4,h5,h6{
		font-size: 2rem;
		padding-top: 20px;
	}

	li{
		list-style: none;
	}

	img{
		max-height: 220px;
		margin: auto;
	}

	a{
		.button;
	}

	footer{
		padding-top: 40px;
		text-align: center;
	}

.tablet({
	.inner{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	header{
		width: 100%;
	}

	aside{
		width: 48%;

		img{
			width: 100%;
			max-height: none;
			max-width: 400px;
			margin: auto;
		}
	}

	article{
		width: 48%;
		margin-top: -20px;
	}
});

.desktop({

});
}
.block.simple-notice{
	position: relative;
	background: @primary;
	color: @secondaryText;
	padding: 50px 20px;
	text-align: center;
	font-weight: bold;
	font-size: 2rem;

	a {
		color: @secondaryText;
		&:hover, &:focus {
			text-decoration: underline;
		}
	}

.tablet({
	br{
		display: none;
	}
});

.desktop({

});
}
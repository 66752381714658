html {
	font-size: 62.5%;
}

body{
	font-size: 1.6rem;
	line-height: 1.75;
	color: @text;
	font-family: @main;
}

img{
	display: block;
}

#wrap{
	overflow: hidden;
}

#mainwrap{
}

.inner{
	position: relative;
	margin: auto;
	max-width: 1170px;
	width: 100%;
}

.text-content{
	padding: 40px 0 100px;
	h1,h2,h3,h4,h5,h6{
		text-align: left;
		padding-top: 30px;
	}
}

#cookie-box {
	z-index: 999999 !important;
}

article.text-content {
	// padding: 40px 20px;
	padding: 40px 20px 100px;
	h1,h2,h3,h4,h5,h6{
		text-align: left;
		padding-top: 30px;
	}
	ul, ol {
		padding-left: 20px;
	}

	.inner {
		border-top: 2px solid var(--primary);
		padding-top: 30px;
	}
}

.wow {
	opacity: 0;
	visibility: hidden;
	animation-fill-mode: forwards;
}

.animation-ended {
	opacity: 1;
	visibility: visible;
}

#rs-builder {
	.wow {
		opacity: 1 !important;
		visibility: visible !important;
		// animation-fill-mode: forwards;
	}
}
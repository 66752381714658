.block.locations-carousel, .block.locations-cards {
  padding: 80px 0;
  position: relative;

  *, *::before, *::after {
    box-sizing: border-box;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #fff;
    opacity: 0.8;
  }

  .swiper {
    height: 300px;
    .swiper-wrapper {
      display: flex;
      align-items: center;
    }
    .swiper-slide {
      height: 100%;
      transition: all 0.4s ease;
      user-select: none;
      position: relative;
      container-type: size;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 100%;
        width: 100%;
        position: relative;
        font-size: 3rem;
        font-weight: bold;
        transition: all ease 0.4s;
        padding: 10px;
      }
      .location-name {
        position: absolute;
        top: 50%;
        width: 100%;
        text-transform: uppercase;
        transform: translateY(-50%);
        padding: 0 10px;
        pointer-events: none;
        * {
          color: #fff;
          font-size: 3rem; // Fallback
          font-size: 7.6cqh;
          font-weight: bold;
          text-shadow: 2px 2px 2px black;
          word-break: break-word;
          hyphens: auto;
        }
      }
      img {
        position: absolute;
        display: block;
        object-fit: cover;
        height: 100%;
        width: 100%;
        transition: all 0.4s ease;
        user-select: none;
        z-index: 0;
      }
    }

    .mouse-blocker {
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      top: 0;
      z-index: 1;
      &.left {
        left: 0;
      }
      &.right {
        right: 0;
      }
    }
    .swiper-button-next, .swiper-button-prev {
      padding: 10px;
      height: fit-content;
      width: fit-content;
      background-color: rgba(0,0,0,0.4);
      border-radius: 50%;
      transition: all ease 0.4s;
      &::after {
        transition: all ease 0.4s;
        color: #fff;
        font-size: 3rem;
      }

      &:hover, &:focus-visible, &:focus {
        outline: none;
      }
      &:hover, &:focus-visible {
        background-color: rgba(255,255,255,0.4);
        &::after {
          color: #000;
        }
      }
    }
    .swiper-button-next {
      &::after {
        content: "next next"
      }
    }
    .swiper-button-prev {
      &::after {
        content: "prev prev"
      }
    }
  }

  .desktop({
    .swiper {
      height: 500px;
      .swiper-wrapper {
        display: flex;
        align-items: center;
      }
      .swiper-slide {
        flex: 0 0 calc(20% - 10px);
        min-width: 275px;
        height: 400px;
        transition: all 0.4s ease;
        user-select: none;
        img {
          transition: all 0.4s ease;
          user-select: none;
        }
        &:hover, &:focus-within {
          flex: 0 0 calc(25% - 10px);
          height: 475px;
        }
      }
      .mouse-blocker {
        height: 100%;
        width: 75px;
        pointer-events: auto;
      }
    };
  });
}

.block.locations-cards {
  .desktop({
    .swiper {
      padding: 0 40px;
      .swiper-wrapper {
        justify-content: center;
        max-width: 1425px;
        margin: 0 auto;
      }
      .swiper-slide {
        flex: 0 0 calc(20% - 10px);
        min-width: unset;
        margin: 5px;
      }
      .mouse-blocker, .swiper-button-next, .swiper-button-prev {
        display: none
      }
    }
  });
}

#rs-builder {
  .block.locations-carousel, .block.locations-cards {
    .swiper {
      height: auto;
    }
    .swiper-wrapper {
      flex-wrap: wrap;
    }
    .swiper-slide {
      flex: 0 0 calc(20% - 10px);
      height: 300px;
      margin: 5px;
      pointer-events: none;
      display: flex;
      flex-direction: column;
      a {
        position: relative;
        font-size: 3rem;
        pointer-events: all;
      }
      .location-name {
        position: relative;
        top: unset;
        bottom: unset;
        left: 0;
        right: 0;
        pointer-events: all;
        background-color: #d4d4d4;
        transform: unset;
        * {
          color: #000;
          text-shadow: none;
        }
      }
    }
    .tablet({
      .swiper {
        .swiper-slide {
          &:hover, &:focus-within {
            flex: 0 0 calc(20% - 10px);
            height: 300px;
          }
        }
        .mouse-blocker, .swiper-button-next, .swiper-button-prev {
          display: none
        }
      };
    });
  }
}

#rs-builder {
  .block.locations-carousel, .block.locations-cards {
    .swiper {
      .swiper-slide {
        .location-name {
          pointer-events: all;
        }
      }
    }
  }
}
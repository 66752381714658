.block.location-hero {
  height: 80vh;
  margin-bottom: 40px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  *, *::after, *::before {
    box-sizing: border-box;
  }
  .inner {
    position: relative;
    &::after {
      position: absolute;
      content: "";
      height: 2px;
      width: 100%;
      top: -20px;
      left: 0;
      background-color: var(--primary);
    }
  }
  .header {
    background-color: #fff;
    padding: 25px 15px;
    .title {
      * {
        font-size: 3.2rem;
        font-weight: bold;
        text-align: left;
      }
    }
    .subtitle {
      * {
        font-size: 2.4rem;
        font-weight: bold;
        text-align: left;
      }
    }
  }
}
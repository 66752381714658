@import '../base/_utilities';

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css');



// universal
* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&:focus-visible {
		outline: @primary auto 3px;
	}
}

img{
	max-width: 100%;
}


// links
a, a:visited {
	text-decoration: none;
	transition: ease all .3s;
	outline: none;

	&:hover, &:focus {
		text-decoration: none;
	}

	&:active {
		text-decoration: none;
	}

	/* mobile tap color */
	&:link {
		-webkit-tap-highlight-color : rgba( 0, 0, 0, 0.3 );
	}
}


// headings
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
	text-rendering: optimizelegibility;
	margin: 0;
	line-height: 1.5;
	font-weight: normal;
	font-family: @heading;
	font-size: 3.25rem;
	text-align: center;
	color: @primaryText;

	em{
		font-size: 1.75rem;
		color: @secondaryText;
		display: block;
		font-family: @main;
		font-style: normal;
		padding-bottom: 15px;
	}
}

.btn {
	display: inline-block;
	appearance: none;
	background: transparent;
	border-radius: 0;
	text-align: center;
	cursor: pointer;
	font-size: 1rem;
	line-height: normal;

	&:hover, &:focus, &:active {

	}
}

.outline{
	background: none;
	border: 2px solid @blue;
	color: #000;
}

p {
	margin: 0;
}

ul, ol {
	margin: 0;
	padding:0;
}

p, ul, ol, dl {
	// + p, + ul, + ol, + dl {
	// 	margin-top: 1.5em;
	// }
}

nav, .nav, nav ul, .nav ul {
	list-style-type: none;
	padding-left: 0;
}

hr {
	border:none;
	background:#eee;
	height:2px;
	width:60px;
	margin:35px auto 0;
}

.alignleft, .alignright {
	margin-bottom: 1em;
}

address {
	font-style: normal;
}

.center {
	text-align: center;
}

.upper {
	text-transform: uppercase;
}

.screen-reader-text {
	position: absolute !important;
	left: -999em;
}

.html-times {
	font-size: 1.5em;
	vertical-align: sub;
}


.tablet({
	// h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
	// 	font-size: 3rem;
	// }
});


.lato-thin {
	font-family: "Lato", sans-serif;
	font-weight: 100;
	font-style: normal;
  }

  .lato-light {
	font-family: "Lato", sans-serif;
	font-weight: 300;
	font-style: normal;
  }

  .lato-regular {
	font-family: "Lato", sans-serif;
	font-weight: 400;
	font-style: normal;
  }

  .lato-bold {
	font-family: "Lato", sans-serif;
	font-weight: 700;
	font-style: normal;
  }

  .lato-black {
	font-family: "Lato", sans-serif;
	font-weight: 900;
	font-style: normal;
  }

  .lato-thin-italic {
	font-family: "Lato", sans-serif;
	font-weight: 100;
	font-style: italic;
  }

  .lato-light-italic {
	font-family: "Lato", sans-serif;
	font-weight: 300;
	font-style: italic;
  }

  .lato-regular-italic {
	font-family: "Lato", sans-serif;
	font-weight: 400;
	font-style: italic;
  }

  .lato-bold-italic {
	font-family: "Lato", sans-serif;
	font-weight: 700;
	font-style: italic;
  }

  .lato-black-italic {
	font-family: "Lato", sans-serif;
	font-weight: 900;
	font-style: italic;
  }

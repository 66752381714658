.block.text{
	position: relative;
	padding: 1px 20px;

	&.text-center{
		background: @gray-lt;
		text-align: center;
		h1,h2,h3,h4,h5,h6{
			text-align: center;
		}

		h3{
			font-size: 2rem;
		}
		.inner{
			border-top:0;
		}
	}

	aside {
		gap: 5px;
    display: flex;
    flex-direction: column;
	}

	.inner{
		padding: 40px 0;
		border-top: 2px solid @primary;
	}

	&.img-left{
		.inner{
			border-top: none;
		}
		aside{
			padding-bottom: 25px;
		}
	}

	h1,h2,h3,h4,h5,h6{
		text-align: left;
		font-weight: bold;
		padding-bottom: 20px;
	}

	p, ul, ol, dl{
		list-style: inside;
		padding-bottom: 20px;
	}


.tablet({
	.inner{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&.img-right{
		aside{
			width: 35%;
			padding-top: 50px;
		}

		article{
			width: 60%;
		}
	}

	&.text-center{
		padding: 50px 20px;

		.inner{
			max-width: 90%;
			margin: auto;
		}

		h3{
			max-width: 700px;
			margin: auto;
		}
	}

	&.img-left{
		aside{
			width: 45%;
		}

		article{
			width: 50%;
		}

		header{
			width: 100%;
		}
	}
});

.desktop({

});
}
.block.award-buttons {
  padding: 50px 20px 50px;
  background: @gray-lt;

  .inner {
    .title {
      text-align: center;
      margin-bottom: 20px;
      * {
        font-size: 2.8rem;
        font-weight: bold;
      }
    }
    .body {
      margin-bottom: 20px;
      max-width: 900px;
      margin-inline: auto;
    }
    .awards-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 20px;
      .award-btn {
        aspect-ratio: 1/1;
        width: calc(33% - 40px);
        min-width: 100px;
        max-width: 175px;
        border-radius: 50%;
        overflow: hidden;
        img {
          display: block;
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .tablet({
    padding: 60px 20px 60px;
    .inner {
      .awards-list {
        gap: 40px;
        .award-btn {
          width: calc(20% - 40px);
        }
      }
    }
  });
}
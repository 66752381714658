.block.timeline {
  box-sizing: border-box;
  position: relative;
  *, *:before, *:after {
    box-sizing: inherit;
  }
  color: @secondaryText;

  h3 {
    position: relative;
    text-align: center;
    line-height: 1.2;
    .fontSize(14.0, 20, 27);
    z-index: 1;
    color: @secondaryHeading;
  }

  p {
    position: relative;
    text-align: center;
    font-size: 1.5rem;
    z-index: 1;
    color: @secondaryText;
  }

  button {
    cursor: pointer;
  }

  .timeline-main {
    .timeline-content {
      display: flex;
      width: 100%;
      height: 80vh;
      min-height: 500px;
      .swiper-slide {
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(to top, color-mix(in srgb, #000 25%, @primary), color-mix(in srgb, #00000063 25%, @primary), transparent 100%);
        }
        position: relative;
        padding: 0 20px 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: end;
        .image {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: -1;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            filter: grayscale(100%);
          }
        }
        .year, .summary {
          max-width: 768px;
        }
        .summary {
          margin-bottom: 10px;
        }
      }
    }
  }

  .year-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    margin: 0 auto;
    padding: 10px 50px;
    .swiper-slide {
      padding: 0;
      background-color: transparent;
      border: none;
      color: @secondaryText;
      display: flex;
      align-items: center;
      justify-content: center;
      &:focus {
        outline: none;
      }
      .year-item {
        display: block;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: @secondaryText;
        color: transparent;
        font-size: 0;
      }
      .line {
        display: block;
        height: 2px;
        background: currentColor;
        flex: 1;
      }
      &.swiper-slide:first-of-type, &.current-active {
          .year-item, .line:first-of-type {
            background-color: #BA932E;
            border-color: #BA932E;
            color: #FFFFFF;
          }
      }
      &.active {
        .year-item, .line {
          background-color: #BA932E;
          border-color: #BA932E;
          color: #FFFFFF;
        }
      }
      &:focus-visible, &:hover {
        &.active, &.swiper-slide:first-of-type, &.current-active {
          .year-item {
            background-color: #FFFFFF;
          }
        }
        outline: none;
        .year-item {
          background-color: #BA932E;
        }
      }
    }

    .swiper-button {
      position: absolute;
      color: @secondaryText;
      border: 2px solid currentColor;
      border-radius: 50%;
      padding: 5px;
      aspect-ratio: 1/1;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      span {
        height: 20px;
        width: 20px;
      }
      svg {
        fill: currentColor;
        height: 100%;
        width: 100%;
      }
      &.next {
        right: 16px;
        &.inactive {
          opacity: 0.4;
        }
      }
      &.prev {
        left: 16px;
        &.inactive {
          opacity: 0.4;
        }
      }
      &:focus-visible, &:hover {
        outline: none;
        border-color: #BA932E;
        color:#BA932E;
      }
    }
  }

  .tablet({
    .timeline-main {
      .swiper-wrapper {
        .swiper-slide {
          position: relative;
          padding-bottom: 80px;
        }
      }
    }
    .year-wrapper {
      height: 80px;
      .swiper-slide {
        .year-item {
          display: block;
          border: 2px solid currentColor;
          border-radius: 50%;
          padding: 5px;
          aspect-ratio: 1/1;
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.4rem;
          height: unset;
          width: unset;
          background-color: transparent;
          color: @secondaryText;
        }
        .line {
          height: 2px;
          background: currentColor;
          flex: 1;
        }
        &:focus-visible, &:hover {
          &.active, &.swiper-slide:first-of-type, &.current-active {
            .year-item {
              border-color: #FFFFFF;
              background-color: #BA932E;
            }
          }
          outline: none;
          .year-item {
            border-color: #BA932E;
            background-color: transparent
          }
        }
      }
    }
  });

  .desktop({
    .timeline-main {
      .timeline-content {
        .swiper-slide {
          position: relative;
          padding-bottom: 100px;
        }
      }
    }
    .year-wrapper {
      height: 100px;
    }
  });
}

#rs-builder {
  .block.timeline {
    .swiper {
      .swiper-wrapper {
        height: unset;
      }
      .timeline-content {
        flex-direction: column;
        .swiper-slide {
          &::before {
            display: none;
          }
          display: flex;
          flex-direction: row;
          padding-bottom: 5px;
          padding-top: 5px;
          border-bottom: 2px solid black;
          h3 {
            font-size: 2rem;
            color: black;
          }
          p {
            font-size: 1.6rem;
            color: black;
          }
          .image {
            position: relative;
            aspect-ratio: 1/1;
            width: 200px;
            height: 200px;
            z-index: 0;
            img {
              filter: grayscale(0);
            }
          }
          .year {
            width: 200px;
          }
          .summary {
            width: calc(100% - 400px);
          }
        }

      }
    }
    .swiper-button {
      display: none;
    }
  }
}
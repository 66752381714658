.block.logo-text{
	position: relative;
	padding: 1px 20px;

	.inner{
		padding: 40px 0;
		border-top: 2px solid @primary;
	}

	h1,h2,h3,h4,h5,h6{
		text-align: left;
		font-weight: bold;
		padding-bottom: 20px;
	}

	header{
		text-transform: uppercase;
	}

	p, ul, ol, dl{
		list-style: inside;
		padding-bottom: 20px;
	}

.tablet({
	.inner{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	header{
		width: 100%;
	}

	figure{
		width: 15%;
	}

	article{
		width: 65%;

		h3,h4,h5,h6{
			font-size: 2.5rem;
			text-transform: none;
		}
	}

	figure + article{
		width: 65%;
		padding-left: 2.5%;
		padding-right: 17.5%;
	}
});

.desktop({

});
}
#footer{
	position: relative;
	padding: 50px 25px;
	background: @black;
	color: @secondaryText;
	.loading {
		display: none !important;
	}
	a, button {
		&:hover, &:focus {
			text-decoration: underline;
		}
	}
	h1,h2,h3,h4,h5,h6{
		color: @secondaryHeading;
		text-align: left;
		font-size: 2rem;
		font-weight: bold;
		padding-bottom: 20px;
		margin:0;
	}

	li{
		list-style: none;
	}

	a{
		color: @secondaryText;
	}

	.ignore {
		display: none;
	}

	.logo-feature{
		padding: 35px 0;

		> a{
			display: block;
			width: 100%;
			max-width: 350px;
			margin: -20px auto 0;
			img {
				width: 100%;
			}
		}

		h5{
			padding-top: 25px;
			text-align: center;
		}

		ul{
			display: flex;
			justify-content: center;
		}

		li{
			padding-right: 20px;
			svg{
				height: 20px;
				width: 20px;
			}
			&:hover, &:focus, &:focus-within {
				* {
					fill: #2859a7;
				}
			}
		}
	}


	nav {
		width: 100%;
		a, button {
			text-transform: uppercase;
		}
		li {
			width: fit-content;
			&.dropdown {
				width: fit-content;
				.dropdown-btn {
					color: inherit;
					padding: 5px 2.5rem 5px 0;
					background-color: transparent;
					border: none;
					border-radius: 0;
					position: relative;
					display: block;
				}
				&.toggled {
					button {
						text-decoration: underline;
					}
				}
				.dropdown-menu {
					position: relative;
					display: block;
					width: fit-content;
					padding: 8px;
					padding-left: 20px;
					li {
						padding: 0;
						a {
							white-space: nowrap;
							display: block;
							text-align: left;
							font-size: 0.8em;
							color: #fff;
						}
					}
				}
			}
		}
	}

	.contact{
		p{
			padding-bottom: 15px;
		}
		img{
			width: 110px;
		}
	}

	.copyright{
		font-size: 1.4rem;
		padding-top: 25px;
	}



.tablet({
	.inner{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.logo-feature, .navigation{
		width: 33%;
	}

	.contact{
		text-align: right;
		width: 33%;

		h6{
			text-align: right;
		}

		p{
			overflow: hidden;
		}

		img{
			margin-right:0;
			float: right;
		}
	}

	.copyright{
		width: 100%;
		text-align: center;
	}
});

.desktop({
		nav {
			li {
				&.dropdown {
						position: relative;
						.dropdown-btn {
							&::after {
								right: 0;
								border: 3px solid transparent;
								border-color: #FFF;
								border-radius: 2px;
								border-right: 0;
								border-top: 0;
								content: " ";
								display: block;
								height: 0.4em;
								pointer-events: none;
								position: absolute;
								top: 50%;
								transform: rotate(-45deg) translateY(-100%);
								transform-origin: center;
								width: 0.4em;
							}

							&:focus-within {
								text-decoration: underline;
							}

						}
						.dropdown-menu {
							position: absolute;
							display: none;
							top: 0;
							right: 0;
							transform: translate(100%, 0);
							width: fit-content;
							background-color: #fff;
							box-shadow: 0 8px 8px rgba(10, 10, 10, .1);
							li {
								padding: 0;
								a {
									white-space: nowrap;
									display: block;
									text-align: left;
									padding: 0px 16px 0px 16px;
									color: #000;
								}
							}
						}
					}
			}
	}
});
}
.block.serving{
	position: relative;
	padding: 50px 20px;
	background: @gray-lt;

	h1,h2,h3,h4,h5,h6{
		padding: 0 0 20px 0;
		font-weight: bold;
	}

	h3,h4,h5,h6{
		font-size: 2rem;
		text-transform: uppercase;
		padding-top: 20px;
	}

	li{
		list-style: none;
	}

	img{
		max-height: 220px;
		margin: auto;
	}

.tablet({
	ul{
		display: flex;
	}

	li{
		width: 33%;
	}
});

.desktop({

});
}
#nav{
	position: relative;
	height: 58px;
	padding: 25px 15px;
	background: rgba(255, 255, 255, 0.8);
	z-index: 20;

	#logo{
		width: 180px;
		display: block;
		z-index: 6;
		position: relative;
		img{
			display: block;
			width: 100%;
		}
	}

	#grip{
		position: absolute;
		top: 0;
		right: 0;
		height: 48px;
		width: 50px;
		background: @blue;
		border: none;
		color: #fff;
		z-index: 100;
		display: flex;
    align-items: end;
    justify-content: center;

		.line-container {
			height: fit-content;
    	width: fit-content;
			padding-bottom: 12px;
			transition: all 0.4s ease;
		}
		.line {
			background-color: #fff;
			width: 35px;
			height: 1.2px;
			margin-top: 5px;
			display: block;
			transition: all 0.4s ease;
		}

		// &.opened {
		// 	.line-container {
		// 		padding-bottom: 10px;
		// 	}
		// 	.line:nth-child(1) {
		// 		transform: rotate(-45deg) translate(0px, 8px);
		// 		height: 2px;
		// 		transform-origin: left bottom;
		// 	}
		// 	.line:nth-child(2) {
		// 		transform: rotate(45deg) translate(-5px, 4px);
		// 		height: 2px;
		// 		transform-origin: right bottom;
		// 	}
		// }


	// 	svg{
	// 		height: 40px;
	// 		width: 40px;
	// 		display: block;
	// 		margin: 5px auto;
	// 	}
	}

	.menu-wrapper {
		position: fixed;
		justify-content: center;
		top:0;
		left:0;
		right:0;
		bottom:0;
		background: #fff;
		z-index: 5;
		display: none;
		overflow-y: auto;
		&.show {
			display: flex;
			.dropdown-menu {
				display: block !important;
			}
		}
	}
	.menu{
		margin-top: 110px;
		height: fit-content;
		display: flex;
		justify-content: center;
		align-items: center;
		font-family: @special;
	}

	ul{
		text-align: center;
		text-transform: uppercase;
		list-style: none;
		li{
			font-weight: bold;
			padding: 5px 0;


			li{
				font-weight: normal;
			}
		}

		a{
			font-size: 1.8rem;
			color: @text;
		}
	}

	.dropdown {
		.dropdown-menu {

			li {
				a {
					font-size: 0.85em;
					color: @text;
				}
			}
		}
	}
	.dropdown-btn {
		font-weight: bold;
		text-transform: uppercase;
		background-color: transparent;
		border: none;
		border-radius: 0;
		position: relative;
	}
	&.toggled {
		// button {
		// 	text-decoration: underline;
		// }
	}


.tablet({

});

.desktop({
	#grip{
		display: none;
	}
	.inner{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.menu-wrapper {
		position: relative;
		display: block;
		background: none;
		left: auto;
		bottom:auto;
		top: auto;
		right: auto;
		overflow-y: unset;
	}
	.menu{
		opacity: 0;
		margin-top: 0;
		height: unset;
		&.loading {
			display: none;
		}
		ul{
			display: flex;
			width: fit-content;
			li{
					padding-left: 25px;
			}
		}

		a{
			font-size: 1.5rem;
			color: @primaryText;

			&:hover{
				text-decoration: underline;
			}
		}

		li ul{
			display: none;
		}
	}
	.dropdown {
		position: relative;
		.dropdown-menu {
			position: absolute;
			bottom: 0;
			left: 15px;
			transform: translateY(100%);
			width: fit-content;
			background-color: #fff;
			box-shadow: 0 8px 8px rgba(10, 10, 10, .1);
			li {
				padding: 0;
				a {
					white-space: nowrap;
					display: block;
					text-align: left;
					padding: 6px 48px 6px 16px;
					font-weight: bold;
					transition: all 0s;
				}
				&:has(a:hover), &:focus-within {
					background-color: #f5f5f5 ;
					a {
						color: @blue;
					}
				}
			}
		}
		.dropdown-btn {
			font-weight: bold;
			padding: 5px 3rem 5px 0;
			text-transform: uppercase;
			background-color: transparent;
			border: none;
			border-radius: 0;
			position: relative;
			font-size: 1.5rem;
			&::after {
				right: 0;
				border: 3px solid transparent;
				border-color: #000;
				border-radius: 2px;
				border-right: 0;
				border-top: 0;
				content: " ";
				display: block;
				height: 0.5em;
				pointer-events: none;
				position: absolute;
				top: 50%;
				transform: rotate(-45deg) translateY(-100%);
				transform-origin: center;
				width: 0.5em;
			}
		}
		&.toggled {
			button {
				text-decoration: underline;
			}
		}
	}

});
}
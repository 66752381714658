.block.awards{
	position: relative;
	background: @black;
	color: @secondaryText;
	padding: 50px 25px;
	text-align: left;

	h2,h3,h4,h5,h6{
		color: @secondaryHeading;
		text-align: left;
		font-weight: bold;
	}

	h3{
		padding-top: 20px;
	}

	header{
		ul{
			display: flex;
			flex-wrap: wrap;

		}
		li{
			position: relative;
			height: 20px;
			line-height: 20px;
			list-style: none;
			padding-right: 20px;
			color: @blue;
			transition: 0.3s color ease;
			* {
				color: @blue;
				transition: 0.3s color ease;
			}
			svg{
				margin-right: 2px;
			}
			&:hover, &:focus-within{
				color: #fff;
				* {
					color: #fff;
				}
			}
		}
	}

	a{
		color: @blue;
		transition: 0.3s color ease;
		&:hover, &:focus{
			color: #fff;
		}
	}

.tablet({
	header{
		ul{
			padding-top: 10px;
		}
	}

});

.desktop({

});
}
.block.services{
	position: relative;
	padding: 50px 25px;
	text-align: center;

	h2,h3,h4,h5,h6{
		font-weight: bold;
		font-size: 2rem;
		padding-bottom: 15px;
	}

	.item{
		padding-bottom: 25px;
		> a{
			display: block;
		}

		>div{
			padding: 20px 15px;
			background: @gray-lt;

			a{
				.button;
			}
		}
	}

	p+p{
		padding-top: 25px;
	}

.tablet({
	article{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.item{
			width: 31%;
			font-size: 1.5rem;
		}
	}
});

.desktop({

});
}
.block.contact{
	position: relative;
	padding: 1px 20px;

	*, *::before, *::after {
		box-sizing: border-box;
	}

	.inner{
		padding: 40px 0;
		border-top: 2px solid @primary;
	}

	aside{
		padding: 24px 0;
		a {
			color: @text;
			display: block;
			&:hover, &:focus {
				text-decoration: underline;
			}
		}
	}

	h1,h2,h3,h4,h5,h6{
		text-align: left;
		font-weight: bold;
		padding-bottom: 20px;
	}

	p, ul, ol, dl{
		list-style: inside;
		padding-bottom: 20px;
	}

	#contact-map {
		height: 320px;
		width: 100%;
	}

	.social{
		display: flex;
		padding-top: 24px;
		li{
			list-style: none;
			padding-right: 20px;
			svg{
				height: 24px;
				width: 24px;
				* {
					fill: #2859a7;
					transition: all 0.4s ease;
				}
			}
			&:hover, &:focus-within {
				svg{
					* {
						fill: @primary;
					}
				}
			}
		}


	}

	.form-group{
		padding-bottom: 20px;
	}

	.about {
		padding: unset;
	}

	.fields {
		display: flex;
		flex-direction: column;
		.form-group:last-child {
			border-bottom: 2px solid #ccc;
		}
	}

	.g-recaptcha, input[type="submit"] {
		width: 75%;
		margin-top: 40px;
		margin-left: auto;

		&::before {
			content: "* required";
			position: absolute;
			left: -32%;
			color: @text;
			font-weight: 400;
			pointer-events: none;
		}
		&::after {
			content: "";
			position: absolute;
			height: 2px;
			background-color: #f5f5f5;
			width: calc(100% * 4/3);
			top: -20px;
			right: 0;
			pointer-events: none;
		}
	}

.tablet({
	.inner{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	header{
		width: 100%;
	}

	aside{
		width: 33%;
		padding: 0 12px;
	}

	article{
		width: 67%;
		padding-left: 50px;
	}

	.fields {
		flex-direction: row;
	}

	.form-group{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.form-group:has(input[required]) {
		label::after {
			content: "*";
			margin-left: 5px;
			font-size: 1em;
			vertical-align: super;
		}
	}

	label{
		width: 25%;
	}

	input, textarea, select{
		width: 75%;
	}

	#contact-map {
		height: 400px;
	}
});

.desktop({

});
}
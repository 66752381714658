.block.featured-image-text {
  *, *::before, *::after {
    box-sizing: border-box;
  }

  .inner {
    display: flex;
    flex-direction: column;
    max-width: unset;
  }

  .image-wrapper,
    .content-wrapper {
      width: 100%;
    }

  .image-wrapper {
    img {
      display: block;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .content-wrapper {
    padding: 50px 20px;
    background: @primary;
    position: relative;
    * {
      color: @text;
      text-align: left;
    }
    &::before {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #fff;
      opacity: 0.85;
      z-index: 0;
    }
    .title {
      margin-bottom: 30px;
      position: relative;
      * {
        font-size: 2.8rem;
        font-weight: bold;
      }
    }
    .subtitle {
      margin-bottom: 20px;
      position: relative;
      * {
        font-size: 2.4rem;
        font-weight: bold;
      }
    }
    .body {
      position: relative;
      p {
        margin-bottom: 10px;
      }
      ul, ol {
        padding-left: 20px;
      }

      ul {
        list-style: none;
        li {
          position: relative;
          margin-bottom: 20px;
          &::before {
            position: absolute;
            content: "";
            height: 8px;
            width: 8px;
            border-radius: 50%;
            background-color: @text;
            left: -20px;
            top: 1rem;
          }
        }
      }
      a {
        position: relative;
        padding: 10px 40px;
        margin: 20px 0 0;
        color: #fff;
        display: inline-block;
        border-radius: 4px;
        font-family: @text;
        font-size: 1.5rem;
        max-width: 100%;
        text-align: center;
        font-weight: bold;
        letter-spacing: 2px;
        background: @primary;
        &:hover, &:focus {
          background: var(--secondary);
        }
      }
    }
  }




  .tablet({
    .inner {
      flex-direction: row;
      align-items: stretch;
    }
    .content-wrapper {
      padding: 80px 40px;
    }

    .image-wrapper {
      position: relative;
      img {
        position: absolute;
      }
    }

    .content-wrapper, .image-wrapper {
      width: 50%;
    }
  });
}

.block.featured-image-text .inner:nth-child(even) {
  .content-wrapper {
    background-color: #fff;
  }

  .tablet({
    flex-direction: row-reverse;
  });
}
.block.header{
	position: relative;
	margin-top: -108px;
	height: 70vh;
	display: flex;
	align-items: center;
	text-align: left;
	color: @secondaryText;
	padding: 0 20px;
	overflow: hidden;



	.inner {
		z-index: 1;
	}

	&.video{
		video{
			position: absolute;
			display: none;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			min-height: 100%;
			object-fit: cover;
		}
	}

	h1,h2,h3,h4,h5,h6{
		color: @secondaryText;
		text-align: left;
		font-weight: bold;
		font-size: 4rem;
		line-height: 1.3;
		text-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
	}


.tablet({
	height: 80vh;
	h1,h2,h3,h4,h5,h6{
		font-size: 5.5rem;
		margin-right: 15%;
	}
});

.desktop({
	&.video{
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: #000;
			opacity: 0.2;
			z-index: 1;
		}
		video{
			display: block;
		}
	}
});
}


#rs-builder {
	.block.header{
		margin-top: 0;
	}
}


.block.header.video + .block.featured-image-text {
	padding-top: 40px;
}
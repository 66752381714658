.block.text-image{
	position: relative;
	padding: 50px 20px;

	h1,h2,h3,h4,h5,h6{
		text-align: left;
		font-weight: bold;
		padding: 0 0 20px 0;
	}

	p{
		padding: 0 0 20px 0;
	}

.tablet({
	padding: 75px 20px;
	.inner{
		display: flex;
		justify-content: space-between;
	}

	article{
		width: 60%;
	}

	figure{
		width: 35%;
		padding-top: 55px;
	}
	
});

.desktop({

});
}

.block.apartments-header,
#apartments-hero,
.block.floorplans,
.block.about,
.block.two-by{
	a{
		color: #3273dc;
	}
	.button{
		background-color: #fff;
		border: 1px solid #dbdbdb;
		color: #363636;
		cursor: pointer;
		justify-content: center;
		padding-left: 1em;
		padding-right: 1em;
		text-align: center;
		white-space: nowrap;
		align-items: center;
		border-radius: 4px;
		box-shadow: none;
		display: inline-flex;
		font-size: 1.5rem;
		font-weight: normal;
		height: 40px;
		line-height: 40px;
		display: inline-block;
		width: auto;
	}

	h1,h2,h4,h5{
		margin-bottom: 0;
		text-align: left;
		font-weight: bold;
	}

	.floorplan{
		h3{
			font-size: 2.4rem;
			text-align: left;
			padding-bottom: 10px;
		}
	}
}


  .apartments-header{
		position: relative;
		text-align: left;
		padding-top: 40px;
		h1, h2,h3,h4,h5{
		margin-bottom: 0;
		text-align: left;
		font-weight: bold;
		}
		p{
		font-size: 24px;
		font-weight: bold;
		}
  }


  .apartments-header{
	padding: 0 25px 25px;
  }


  #apartments-hero{
	position: relative;
	padding: 45px 25px;
	background: #ddd;
	text-align: left !important;
	.inner {
		pointer-events: none;
	}

	.section-bg{
		position: absolute;
		top:0;
		left:0;
		bottom:0;
		right:0;
		background: #ddd;
		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}

	h1, h2,h3,h4,h5{
		margin-bottom: 0;
		text-align: left;
		font-weight: bold;
	}

	.hero-intro{
		padding: 25px;
		background: #fff;
		pointer-events: all;
		p{
			padding-top: 12px;
		}
	}

	@media screen and(min-width:768px){
		.hero-intro{
			margin-left: 60%;
		}
		}
	}

	.inner{
			margin: auto;
			max-width: 1112px;
	}

  .two-by{
	position: relative;
	padding: 25px;

	ul p{
	  padding-bottom: 0;
	  margin-bottom:0;
	}

	@media screen and (min-width: 768px){
	  padding: 55px 25px;

	  .inner{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	  }

	  header{
		width: 100%;
	  }

	  article{
		width: 50%;
	  }

	  aside{
		width: 40%;
	  }

	  &.alt{
		background: #ddd;

		header{
		  order:1;
		}

		aside{
		  order: 2;
		}

		article{
		  order: 3;
		}
	  }
	}

	h2{
	  font-size: 2em;
	  padding-bottom: 25px;
	}

	p, ul{
	  position: relative;
	  clear: both;
	  line-height: 1.5;
	  padding-bottom: 1.5em;
	}

	ul{
	  padding-left: 20px;
	  margin-left: 0;

	  + p{
		padding-top: 22px;
	  }
	}

	li{
	  float: left;
	  width: 45%;
	  padding-right: 5%;
	  list-style: outside disc;
	}

	strong{
	  font-weight: bold;
	}

	aside{
	  text-align: right;
	  overflow: hidden;
	}

	img{
	  margin-right: 0;
	  display: block;
	  width: 75%;
	  float: right;
	  margin-bottom: 15px;

	  + img{
		float: left;
		margin-left: 0;
		margin-right: auto;
	  }
	}

	&.alt{
	  img{
		display: block;
		margin-left: 0;
		width: 75%;
		float: left;
		margin-bottom: 15px;

		+ img{
		  float: right;
		  margin-right: 0;
		  margin-right: auto;
		}
	  }
	}
  }

  .floorplans{
	position: relative;
	padding: 25px;
	clear: both;

	.floorplan{
	  margin-bottom: 35px;
	}

	@media screen and (min-width: 768px){
	  .floorplan{
		display: flex;
		justify-content: space-between;
		padding: 0 !important;
		border: 0 !important;
	  }

	  article{
		order: 1;
		width: 50%;
		padding: 15px;
		border: 1px solid #ddd;
		box-sizing: border-box;
	  }

	  picture{
		order:2;
		width: 45%;

		img{
		  width: 50%;
		  display: block;
		  margin: auto;
		}
	  }

	  p{
		margin-bottom: 0 !important;
	  }
	}

	h2{
	  font-size: 2em;
	}

	.floorplan{
	  padding: 15px;
	  border: 1px solid #ddd;
	}

	p, ul{
	  position: relative;
	  line-height: 1.5;
	  padding-bottom: 1em;
	  padding-top: 0;
	  margin-top:0;
	}

	p{
	  font-weight: bold;
	}

	strong{
	  font-weight: bold;
	}

	img{
	  display: block;
	  width: 100%;
	}

	picture{
	  display: block;
	  padding-bottom: 25px;
	}
  }

  .about{
	position: relative;
	padding: 25px;

	h2{
	  font-size: 2em;
	}

	p, ul{
	  position: relative;
	  line-height: 1.5;
	  padding-bottom: 1.5em;
	}
  }

  @media screen and (min-width: 768px){

	#viewer{
	  position: relative;
	  padding: 25px 0;

	  #list{
		width: 100%;
	  }

	  &.has-map{
		position: relative;
		display: flex;
		justify-content: space-between;
		#map{
		  display: block;
		  width: 48%;
		  background: #eee;
		  position: relative;
		  overflow: hidden;
		}
		iframe, #map-canvas{
		  position: absolute;
		  top:0;
		  left:0;
		  bottom:0;
		  right:0;
		}
		#list{
		  display: block;
		  width: 48%;
		  height: 80vh;
		  max-height: 760px;
		  overflow-y: scroll;
		}
		.apartments{
		  display: flex;
		  justify-content: space-between;
		  flex-wrap: wrap;
		  padding-right: 15px;

		  li{
			width: 48%;
			margin: 0;
		  }
		}
	  }
	  &.not-has-map{

		#map{
		  display: none;
		}

		.apartments{
		  display: flex;
		  justify-content: space-between;
		  flex-wrap: wrap;
		  padding-right: 15px;

		  li{
			width: 23%;
			margin: 0;
		  }
		}
	  }
	}



	.search-bar{
	  display: flex;
	  align-items: center;
	  justify-content: space-between;
	  padding: 20px 0;


	  .drop-option{
		width: auto;
		display: block;
		padding-left: 15px;

		label{
		  display: block;
		  height: 16px;
		  line-height: 16px;
		  font-weight: bold;
		  font-size: 14px;
		}

		select{
		  height: 28px;
		  line-height: 28px;
		  box-sizing: border-box;
		  width: 100px;
		}
	  }

	  .drops{
		width: 70%;
		display: flex;
		justify-content: first baseline;

		h2{
		  font-size: 17px;
		  font-weight: bold;
		  height:50px;
		  line-height: 50px;
		}
	  }

	  .toggles{
		width: 30%;
		padding-top: 0;
	  }
	}
  }


	.block.locations-header {
		.apartments-header {
			padding-top: 20px;
			.inner {
				* {
					font-size: 3rem;
				}
			}
		}
	}


.block.apartments{
	position: relative;
	padding: 1px 20px;


	.inner{
		padding: 40px 0;
		// border-top: 2px solid @primary;
	}

	header{
		h1,h2{
			text-align: left;
			font-weight: bold;
		}

		h2{
			font-size:2.5rem;
		}
	}

	.options{
		padding-top: 20px;
	}

	.refine{
		width: 100%;
		strong{
			font-size: 2.5rem;
		}

		.option {
			width: 100%;
			display: flex;
			justify-content: space-between;
			margin-bottom: 10px;
		}

		label{
			width: 30%;
			font-weight: 700;
			// float: left;
		}

		select{
			// float: right;
			width: 50%;
		}
	}

	.tabs{
		max-width: 350px;
		display: none;
		a{
			width: 50%;
			display: block;
			box-sizing: border-box;
			height: 44px;
			border: 2px solid @primary;
			text-align: center;
			line-height: 40px;
			background: @primary;
			color: #fff;

			&.active{
				background: #fff;
				color: @primary;
			}
		}
	}

	h3{
		text-align: left;
		font-weight: bold;
		padding-bottom: 20px;
	}

	address, p, ul, ol, dl{
		list-style: inside;
		padding: 5px 0;
	}


	article{
		li{
			list-style: none;
			padding: 20px;
			color: @gray-dk;
			font-size: 1.5rem;
			line-height: 1.25;
		}

		h3{
			font-size: 2rem;
			color: @primary;
			padding-bottom: 0;
		}

		li>a{
			height: 150px;
			overflow: hidden;
			display: block;
			position: relative;
			margin-bottom: 5px;

			strong{
				position: absolute;
				bottom:0;
				right:0;
				padding: 6px 18px;
				font-size: 1.4rem;
				background: @primary;
				color: #fff;
				font-weight: bold;
			}
		}

		span{
			display: block;
		}

		a{
			color: @blue;
		}
	}

	figure{
		display: none;
	}
	// &.list{
	// 	figure{
	// 		display: none;
	// 	}
	// }

.tablet({
	.inner{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.tabs{
		display: flex;
	}
	figure{
		display: block;
	}
	&.list{
		figure{
			display: none;
		}
		article{
			width: 100%;
		}
	}

	header{
		width: 100%;
		padding-bottom: 30px;
	}

	.options{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.refine{
		width: 50%;
		display: flex;
		flex-wrap: wrap;
		justify-content: start;

		strong{
			font-size: 1.8rem;
			width: 100%;
			padding-right: 20px;
		}

		strong {
			line-height: 38px;
			// height: 38px;
		}

		label{
			padding-right: 5px;
			float: none;
		}

		.option {
			// max-width: 45%;
			display: flex;
			flex-direction: column;
			width: fit-content;
			padding: 0 15px 0 0;
		}

		select{
			float: none;
			width: 100px;
			float: right;
		}
	}

	.tabs{
		a{
			width: 170px;
		}
	}



	figure{
		order: 2;
		width: 48%;
		height: 70vh;
		overflow: hidden;
		position: relative;

		#map-canvas{
			top:0;
			left:0;
			right:0;
			bottom:0;
			content:"";
			position: absolute;
		}
	}


	&.list article ul li{
		width: 24%;
	}

	article{
		width: 48%;
		height: 70vh;
		overflow-y: scroll;

		order: 3;

		ul{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			li{
				padding: 0 0 20px 0;
				font-size: 1.4rem;
				line-height: 1.5;
			}

			span a{
				font-weight: bold;
			}
		}

		h3{
			font-size: 1.6rem;
		}

		li{
			padding: 20px 0;
			width: 48%;

			&:first-of-type{
				padding-top: 0;
			}
		}
	}
});

.desktop({
	.refine{
		width: 50%;
		display: flex;
		flex-wrap: wrap;
		justify-content: start;

		strong{
			font-size: 1.8rem;
			padding-right: 20px;
			width: fit-content;
		}

		.option {
			display: flex;
			flex-direction: column;
			width: fit-content;
		}

		select{
			float: none;
			width: 100px;
		}
	}
});
}
.block.team {
  box-sizing: border-box;
  padding: 50px 20px;
  position: relative;
  *, *:before, *:after {
    box-sizing: inherit;
  }

  button {
    cursor: pointer;
    padding: 0;
    margin: 0;
    border: 0;
    background: none;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .team-list {
		margin: 0;
		padding: 10px 0 0 0;
		list-style-type: none;
		gap: 25px;
		display: flex;
		flex-wrap: wrap;
    justify-content: center;
		.team-member {
			margin: 0 auto;
      width: 60%;
			max-width: 500px;
			min-width: 250px;
      height: fit-content;
      .member-bio-toggle {
        font-size: 1.4rem;
        text-align: center;
        border-bottom:6px solid @primary;
        border-radius: 6px;
        position: relative;
      }
			.member-details {
				box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.1);
        padding: 5px 5px 30px;
        min-height: 125px;
        width: 100%;

        .down-carat {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          background-color: @primary;
          width: 50px;
          height: 25px;
          border-radius: 50px 50px 0 0;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: background-color ease 0.3s;
          .carat {
            height: 10px;
            width: 10px;
            border-left: 2px solid #fff;
            border-top: 2px solid #fff;
            transform: rotate(-135deg);
          }
        }
			}
      .member-bio {
        position: relative;
        width: 100vw;
        max-width: 1112px;
        padding: 10px;
        border: 2px solid #333;
        margin-top: 30px;
        display: none;
      }
      &.active {
        .member-bio-toggle {
          box-shadow: 0 15px 15px rgba(0, 0, 0, 0.2);
          border-bottom:6px solid @secondary;
        }
        .member-bio-toggle {
          &::after {
            content: '';
            position: absolute;
            bottom: -38px;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-left: 29px solid transparent;
            border-right: 29px solid transparent;
            border-bottom: 29px solid white;
            z-index: 1;
          }
        }
        .member-bio-toggle {
          &::before {
            content: '';
            position: absolute;
            bottom: -37px;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-left: 30px solid transparent;
            border-right: 30px solid transparent;
            border-bottom: 30px solid #333;
            z-index: 1;
          }
        }
        .member-bio {
          display: block;
        }
        .down-carat {
          background-color: @secondary;
        }
      }

      &.no-bio {
        .down-carat {
          display: none;
        }
      }
		}
	}

	.social-list {
		list-style: none;
		display: flex;
		flex-direction: column;
		// gap: 5px;
		li {
			transition: all 0.3s ease;
			a {
				background-color: #FFF6F0;
				border-radius: 50%;
				padding: 10px;
				display: inline-block;
				svg {
					width: 20px;
					height: 20px;
					fill: #494949;
					transition: fill 0.3s;
					display: block;
				}
			}
			a[href=""], a[href="tel:+1"], a[href="mailto:"], a[href*="undefined"],
      a[href$="undefined"] {
				display: none;
			}
		}
	}

	.team-member-top {
		position: relative;
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
		overflow: hidden;
		.socials {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			padding: 10px;
		}
		.image {
			margin: 0 auto;
      width: 100%;
			img {
				width: 100%;
				object-fit: cover;
    		height: 270px;
        object-position: top;
			}
		}
	}


	h3{
		padding-top: 10px;
		font-size: 2rem;
	}

	p{
		padding-bottom: 10px;
	}


  .tablet({
    .team-list{
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      overflow: visible;
      gap: 15px;
      .team-member {
        margin: 0;
        width: calc(25% - 12px);
        max-width: 270px;

        .member-bio-toggle {
          box-shadow: 0 15px 15px rgba(0,0,0,.1);
        transition: box-shadow 0.3s ease, border-bottom 0.3s ease;
        }
        .image {
          margin: 0;
        }
        .member-details {
          box-shadow: none;
        }
        .socials {
          width: fit-content;
          height: fit-content;
          padding: 10px;
          .social-link {
            visibility: hidden;
            opacity: 0;
            transform: translateX(-10px);
            padding: 5px;
            transition: transform 0.5s ease, visibility 0.5s ease, opacity 0.5s ease, background-color 0.3s ease;
            svg {
              height: 15px;
              width: 15px;
            }
            &:hover, &:focus {
              background-color: #494949;
              svg {
                fill: #FFF6F0;
              }
            }
          }
        }
        &:hover, &:focus-within {
          .member-bio-toggle {
            box-shadow: 0 15px 15px rgba(0, 0, 0, 0.2);
            border-bottom:6px solid @secondary;
          }
          .social-link {
            visibility: visible;
            opacity: 1;
            transform: translateX(10px);
          }
          .down-carat {
            background-color: @secondary;
          }
        }
      }
    }
  });
}
.block.join{
	position: relative;
	padding: 50px 25px;
	background: @gray-lt;

	h2{
		font-weight: bold;
	}

	h3{
		font-style: italic;
		font-size: 2.5rem;
		font-weight: bold;
		padding: 10px 0 15px;
		color: @blue;
	}

	figure{
		padding-bottom: 20px;
	}

	p, ul, ol, dl{
		padding-bottom: 1em;
	}

	li{
		list-style: inside;
	}

	a{
		.button;
	}

.tablet({
	.inner{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	header{
		width: 100%;
	}

	figure{
		width: 48%;
	}

	article{
		width: 48%;
	}

});

.desktop({

});
}
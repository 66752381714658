.block.image-stack{
	position: relative;
	padding: 1px 20px;

	.inner{
		padding: 40px 0;
		border-top: 2px solid @primary;
	}

	h1,h2,h3,h4,h5,h6{
		text-align: left;
		font-weight: bold;
		padding-bottom: 20px;
	}

	header{
		text-transform: uppercase;
	}

	p, ul, ol, dl{
		list-style: inside;
		padding-bottom: 20px;
	}

	figure{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.item{
		width: 50%;
		box-sizing: border-box;
		padding: 5px;
	}

.tablet({
	.inner{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	article{
		width: 48%;
	}

	figure{
		width: 48%;
	}
});

.desktop({

});
}
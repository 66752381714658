.block.black-feature{
	position: relative;
	padding: 50px 20px;
	background: @black;
	color: @secondaryText;
	text-align: center;

	.inner{
		max-width: 700px;
	}

	h1,h2,h3,h4,h5,h6{
		color: @secondaryHeading;
		font-weight: bold;
	}

	p{
		padding-top: 20px;
	}
	
	a{
		.button;
		color: @blue;
		font-weight: normal;
		background: none;
		border: 1px solid @secondaryText;

		&:hover{
			color: @secondaryText;
		}
	}
	

.tablet({
	
});

.desktop({

});
}
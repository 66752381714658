.block.testimonials{
	position: relative;
	padding: 50px 20px;
	ul {
		list-style-type: none;
    padding: 0;
    margin: 0;
    display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		height: auto;
	}
	li{
		list-style: none;
		visibility: hidden;
		overflow: hidden;
		opacity: 0;
		height: auto;
		grid-row: 1/1;
		grid-column: 1/1;
		figure{
			float: left;
			padding: 0 15px 0 0;
		}
		&:first-of-type{
			visibility: visible;
			opacity: 1;
		}
		p:last-of-type{
			text-align: right;
		}
	}

.tablet({
	padding: 100px 0;

	ul{
		max-width: 800px;
		margin: auto;
	}

	figure{
		width: 25%;
		img{
			width: 100%;
		}
	}
});
}


#rs-builder {
	.block.testimonials {
		ul {
			display: flex;
			flex-direction: column;
		}
		li{
			display: list-item !important;
			visibility: visible !important;
			margin-bottom: 10px;
			opacity: 1;
			position: relative;
			margin-bottom: 10px;
		}
	}
}